
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'MobileNumberType',
    components: {}
})
export default class MobileNumberType extends Vue {
    @Prop({
        type: [Number, String, Object],
        default: ''
    })
    public value;

    public options: Array<any> = [];

    private created() {
        // 根据店铺生成
        // eslint-disable-next-line eqeqeq
        if (this.shopId == '7') { // 派将
            this.options = [
                // 中國香港
                { Text: '+852', Value: '1622', add: '+852' },
                // 大陆
                { Text: '+86', Value: '1620', add: '' }
            ];
            return;
        }

        // eslint-disable-next-line eqeqeq
        if (this.shopId == '10') { // 泽宝
            this.options = [
                // 大陆
                { Text: '+86', Value: '1620', add: '' },
                // 日本
                { Text: '+81', Value: '1623', add: '+81' },
                // 加拿大
                { Text: '+1', Value: '3931', add: '+1' },
                // 澳大利亚
                { Text: '+61', Value: '3929', add: '+61' },
                // 韩国
                { Text: '+82', Value: '3925', add: '+82' },
                // 新加坡
                { Text: '+65', Value: '1761', add: '+65' },
                // 泰国
                { Text: '+66', Value: '1687', add: '+66' },
                // 马来西亚
                { Text: '+60', Value: '1662', add: '+60' }
            ];
            return;
        }

        // 其它店铺
        this.options = [
            // 台湾
            { Text: '+886', Value: '1621', add: '+886' },
            // 大陆
            { Text: '+86', Value: '1620', add: '' }
        ];

        this.handleChange(this.options[0].Value);
    }

    public handleChange(item: any) {
        this.$emit('input', item);
        const option = this.options.find((e) => e.Value === item);
        this.$emit('change', option || {});
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
