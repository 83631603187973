






































import { Component, Vue, Ref } from 'vue-property-decorator';

import MobileNumberType from './mobile-number-type.vue';
import SlideVerification from './slide-verification.vue';
import VerificationCode from './VerificationCode.vue';
import YwhzRadio from '@/components/PortalSite/YwhzRegionCard/Radio.vue';

import { validators } from '@/utils/validate';
import { Registered } from '@/api/api';
import { otherModule } from '@/store/modules/other';

// import { IsPC, getQueryVariable } from '@/utils/lib';
import { getQueryVariable } from '@/utils/lib';

@Component({
    name: 'Register',
    components: {
        MobileNumberType,
        SlideVerification,
        VerificationCode,
        YwhzRadio
    }
})
export default class Register extends Vue {
    @Ref('form') private readonly formRef: any;
    @Ref('SlideVerification') private readonly SlideVerificationRef: any;

    public code: any = '';
    public is: boolean = false; // 是否同意集运条款
    public formData: any = {
        Phone: '',
        Pwd: '',
        Pwd2: '',
        Source: '',
        CountryId: '',
        VerificationCode: '',
        Device: '1'
    };

    public formRules: any = {
        Phone: [validators.required],
        Pwd: [validators.required],
        Pwd2: [validators.required],
        VerificationCode: [validators.required]
    };

    private async mounted() {
        await this.getSource();
        await this.getAgentClient();
        // 因为宏任务 和微任务原因、这里做单独跳转 。全局哪里 做过滤
        // if (!IsPC() && this.$route.path === '/login/register') {
        //     window.location.href = appFig.vueAdmin + `/H5/pages/login/select-ligin-type?state=1&RegisteredSource=${this.Source}&AgentClientId=${this.Agent}`;
        //     // window.location.href = `http://192.168.1.42:8080/H5/pages/login/select-ligin-type?state=1&RegisteredSource=${this.Source}`;
        // }
    }

    // 获取注册来源
    // 注册来源(淘宝注册:1，LINE注册:2，脸书注册:3, 抖音注册:4，小红书注册:5，TikTok注册:6, 谷歌注册:7，Popln注册:8，网站注册:9,微信公众号注册:10)
    private getSource() {
        let time: any = 0;
        let count = 0;
        return new Promise((resolve) => {
            time = setInterval(() => {
                const RegisteredSource = getQueryVariable('RegisteredSource');
                count++;
                if (count > 10) {
                    clearInterval(time);
                    resolve(true);

                }
                if (RegisteredSource) {
                    otherModule.setRegisteredSource(RegisteredSource);
                    clearInterval(time);
                    resolve(true);
                }
            }, 100);
        });
    }

    // 获取代理来源
    private getAgentClient() {
        let time: any = 0;
        let count = 0;
        return new Promise((resolve) => {
            time = setInterval(() => {
                const agentClientId = getQueryVariable('AgentClientId');
                count++;
                if (count > 10) {
                    clearInterval(time);
                    resolve(true);
                }
                if (agentClientId) {
                    otherModule.setAgentClientId(agentClientId);
                    clearInterval(time);
                    resolve(true);
                }
            }, 100);
        });
    }

    public async onSuccess() {
        try {
            const formData: any = JSON.parse(JSON.stringify(this.formData));
            delete formData.Pwd2;
            const { Data } = await Registered(formData);
            this.jump('/login/RegisterSuccess', {
                Phone: this.formData.Phone,
                Pwd: Data.Pwd,
                Uid: Data.Uid
            });
        } catch (error) {
            console.warn(error);
        }
    }

    public async submit() {
        try {
            this.formData.Source = this.Source;

            if (this.Agent) {
                this.formData.AgentClientId = this.Agent;
            }

            if (this.is === false) {
                const message: any = this.$t('login.register.massage');
                this.$message(message);
                return;
            }

            if (this.formData.Pwd !== this.formData.Pwd2) {
                const message: any = this.$t('login.register.massage4');
                this.$message(message);
                return;
            }

            await this.formRef.validate();
            this.SlideVerificationRef.open();
        } catch (error) {
            console.warn(error);
        }
    }

    // 跳转页面
    public open() {
        const routeData = this.$router.resolve({
            path: '/transport/terms'
        });
        window.open(routeData.href, '_blank');
    }

    public jump(path, query?) {
        this.$router.push({
            path,
            query
        });
    }

    public mobileChange(item) {
        this.code = item.add;
    }

    // 获取来源
    private get Source() {
        return otherModule.registeredSource;
    }

    // 获取代理id
    private get Agent() {
        return otherModule.agentClientId || '';
    }

}
