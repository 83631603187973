












import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetVerifyCode } from '@/api/api';
import { UserModule } from '@/store/modules/user';

@Component({
    name: 'VerificationCodeImage',
    components: {}
})
export default class VerificationCodeImage extends Vue {
    @Prop({
        type: [String, Number],
        default: () => ''
    })
    public value:any;

    public ImgBase64: string = '';

    private async created() {
        this.getCode();
    }

    public async getCode() {
        try {
            // 设置uuid
            await UserModule.setUUid();
            const uuid = this.UUID;
            const { Data } = await GetVerifyCode({
                headers: {
                    VerifyCookie: uuid
                }
            });
            const img = Data.Base64 ? Data.Base64 : Data;
            this.ImgBase64 = 'data:image/png;base64,' + img;
            this.$emit('change', Data);
        } catch (err) {
            console.warn(err);
        }
    }

    public input(value) {
        this.$emit('input', value);
    }

    get UUID() {
        return UserModule.GUID;
    }
}
